import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <script src="https://kit.fontawesome.com/0cb93a5de7.js" crossorigin="anonymous"></script>
        <title>{frontmatter.title} | {siteMetadata.title}</title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <div className="blog-post-container">
        <article className="post">
          
          {!frontmatter.thumbnail && (
            <div className="post-thumbnail">
              <h1 className="post-title">{frontmatter.title}</h1>
              <div className="post-meta"><a href={frontmatter.link1url}>{frontmatter.author}</a></div>              
              <div className="post-meta">{frontmatter.date}</div>
            </div>
          )}
          {!!frontmatter.thumbnail && (
            <div className="post-thumbnail" style={{backgroundImage: `url(${frontmatter.thumbnail})`}}>
              <h1 className="post-title">{frontmatter.title}</h1>
              <div className="post-meta"><a href={frontmatter.link1url}>{frontmatter.author}</a></div>
              <div className="post-meta">{frontmatter.date}</div>
            </div>
          )}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <article class="author card ">
            <div class="auth-container">
              <h2 className="post-title">About Author</h2>
              <img className="auth-dp" src={frontmatter.authdp}></img>
              <div className="post-title">{frontmatter.author}</div>
              <div className="post-meta"><a href={frontmatter.link1url}><i class="fab fa-instagram"></i> {frontmatter.link1}</a>
              &nbsp; | &nbsp; 
              <a href={frontmatter.link2url}><i class="fas fa-external-link-alt"></i> {frontmatter.link2}</a></div>
              <div className="post-meta">{frontmatter.bio}</div>
            </div>
          </article>
        </article>

      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        author
        link1
        link1url
        link2
        link2url
        bio
        authdp
        thumbnail
        metaDescription
      }
    }
  }
`